<template>
  <div class="dashboard">
    <b-card
        title="监护看板"
    >
      <b-row>
        <b-col cols="4">
          <b-form-group
              label="分组"
              label-for="basicInput"
          >
            <el-select
                clearable
                placeholder="请选择分组"
                ref="selectGroup"
                v-model="chooseName"
                @clear="handleSelectClear"
            >
              <el-option hidden :value="chooseName" :label="chooseName"></el-option>
              <el-tree
                  :data="groups"
                  :props="props"
                  node-key="id"
                  :check-on-click-node="true"
                  :default-expanded-keys="[this.checkIds] "
                  :default-checked-keys="[this.checkIds]"
                  accordion
                  show-checkbox
                  check-strictly
                  @check="check"
                  ref="tree"
              >
              </el-tree>
            </el-select>

          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card
    >
      <ul class="mt-2 mb-2 dashboard-list">
        <li v-for="(item,i) in dashboardList" :key="i">
          <div class="list-box"
               :class="item.status == 0 ? 'normalBg' : (item.status == 1?'offLineBg':(item.status == 2?'warningBg':'noDeviceBg'))"
               @click="onClickDashboardDesc(item)">
            <div class="desc"
                 :class="item.status == 0 ? 'normal' : (item.status == 1?'offLine':(item.status == 2?'warning':'noDevice'))">
              {{ item.title }}
            </div>
            <p class="t1">{{ formatterViewStatusOptions(item.status) }}</p>
            <!--<p class="t2">  {{ item.deviceCount }}</p>-->
          </div>
        </li>
      </ul>
      <b-row>
        <b-col cols="12" sm="6" class="d-flex justify-content-sm-end">
          <b-pagination
              v-model="queryParams.currentPage"
              :total-rows="queryParams.total"
              :per-page="queryParams.pageSize"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="currentPageHandleChange"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BCard, BFormTextarea, BFormRadioGroup,
  BPagination, BToast
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import request from '@/api/request'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BFormRadioGroup,
    BPagination,
    ToastificationContent,
    BToast,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      chooseId: '',
      chooseName: '',

      roleFilter: null,
      selected: '0',
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      dashboardList: [
        {
          id: 1,
          name: '房间1',
          status: 0
        },
        {
          id: 10,
          name: '房间2',
          status: 1
        },
        {
          id: 12,
          name: '房间3',
          status: 2
        },
      ],
      props: {
        label: 'name',
        children: 'childNodes'
      },
      count: 1,

      groups: [],

      //分页实体
      queryParams: {
        currentPage: 1,
        pageSize: 64,
        total: null,
        tenantGroupId: null,
      },

      viewStatusOptions: [
        {
          value: 0,
          label: '正常'
        },
        {
          value: 1,
          label: '有设备离线'
        },
        {
          value: 2,
          label: '警报'
        },
        {
          value: 3,
          label: '无设备'
        },
      ],

      checkIds:{},

    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getDashboardList();
    }, 1 * 60 * 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {
    this.groupDatas()
    this.getDashboardList()
  },
  destroyed() {
  },

  methods: {
    groupDatas() {
      request.get('tob/tenant/tenantGroup/listTenantGroupsById', {})
          .then(res => {
            if (res.data.success) {
              this.groups = res.data.data
            }
          })
    },

    handleNodeClick(nodeData) {
      this.$refs.tree.setCheckedKeys([]);
      this.chooseName = nodeData.name
      this.queryParams.tenantGroupId = nodeData.id
      this.changeCheckIds();
      this.$refs.selectGroup.blur()
      this.getDashboardList()
    },

    handleSelectClear() {
      this.queryParams.tenantGroupId = null
      this.$refs.tree.setCheckedKeys([]);
      this.changeCheckIds();
      this.getDashboardList()
    },

    getDashboardList() {
      request.post('tob/tenant/tenantGroupView/pageTenantGroupView', {
        currentPage: this.queryParams.currentPage,
        pageSize: this.queryParams.pageSize,
        tenantGroupId: this.queryParams.tenantGroupId,
      })
          .then(res => {
            if (res.data.success) {
              this.dashboardList = res.data.data.data
              this.queryParams.total = res.data.data.count

              console.log('dashboardList', this.dashboardList)
              console.log('total', this.queryParams.total)
            }
          })
    },

    formatterViewStatusOptions(val) {
      for (let item of this.viewStatusOptions) {
        if (item.value === val) {
          return item.label
        }
      }
      return val
    },

    onClickDashboardDesc(item) {
      if (item.hasChild) {//有子的进入子的
        this.chooseName = item.title
        this.queryParams.tenantGroupId = item.tenantGroupId
        this.changeCheckIds();
        this.getDashboardList()
      } else {//否则进入设备列表
        this.toDeviceListInfo(item)
      }
    },

    getGroupById(id) {
      return undefined
    },

    toDeviceListInfo(item) {
      this.$router.push({
        name: 'dashboard-deviceList',
        params: { row: item }
      })
    },

    currentPageHandleChange(currentPage) {
      this.queryParams.currentPage = currentPage
      this.getDashboardList()
    },

    changeCheckIds(){
      if (this.queryParams.tenantGroupId){
        this.checkIds=this.queryParams.tenantGroupId;
      }else {
        this.checkIds={}
      }
    },

    check(node,list){
      this.handleNodeClick(node)
    },

  },
}
</script>
<style lang="scss">
.dashboard-list {
  display: flex;
  flex-wrap: wrap;
  li {
    flex: 0 0 calc(12.5% - 20px);
    list-style: none;
    margin-right: 20px;
    margin-bottom: 20px;
    text-align: center;

    .list-box {
      height: 100px;
      line-height: 100px;
      position: relative;
      color: #fff;
      padding-top: 40px;

      .desc {
        position: absolute;
        left: 0;
        top: 0;
        width: 84px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }

      p {
        margin-bottom: 0;
      }

      .t1 {
        font-weight: bold;
      }

      .t2 {
        font-weight: 500;
      }
    }

    .offLine {
      background-color: #2199FF;
      color: #fff;
    }

    .offLineBg {
      background-color: #E7F8FF;
      color: #0067AA;
    }

    .warning {
      background-color: #FF4949;
      color: #fff;
    }

    .warningBg {
      background-color: #FFE7E7;
      color: #FF3F3F;
    }

    .noDevice {
      background-color: #676767;
      color: #fff;
    }

    .noDeviceBg {
      background-color: #F1F1F1;
      color: #5A5A5A;
    }

    .normal {
      background-color: #426c26;
      color: #fff;
    }

    .normalBg {
      background-color: #c0deab;
      color: #2e6a07;
    }
  }
}
</style>
